<template>
  <div>
    <!-- For Free Text -->
    <div
      @copy="copyText()"
      v-html="data.questionFreeText"
      style="word-wrap: break-word"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  methods: {
    copyText() {
      const selection = document.getSelection();

      this.$emit("copy-text", selection.toString());
    },
  },
};
</script>
