<template>
  <div>
    <!-- For Free Text -->
    <div
      @copy="copyText()"
      v-html="data.questionFreeText"
      style="word-wrap: break-word"
    ></div>

    <br />
    <br />

    <!-- For uploading image -->
    <div v-for="(file, f) in data.questionFile" :key="f">
      <div class="columns">
        <div class="column is-12">
          <!-- For Displaying Image -->
          <img v-lazy="getUrlFile(file.url)" alt="Image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  methods: {
    getUrlFile(file) {
      return process.env.VUE_APP_BACKEND_URL + file;
    },
    copyText() {
      const selection = document.getSelection();

      this.$emit("copy-text", selection.toString());
    },
  },
};
</script>
