<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="columns">
          <div class="column is-2"></div>
          <div class="column is-8">
            <template v-if="data.min == 0">
              <div class="columns">
                <div
                  v-for="(number, index) in data.max + 1"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered" @copy="copyText()">
                    {{ index }}
                  </b-field>
                </div>
              </div>
            </template>
            <template v-else-if="data.min == 1">
              <div class="columns">
                <div
                  v-for="(number, index) in data.max"
                  :key="index"
                  class="column"
                >
                  <b-field grouped position="is-centered" @copy="copyText()">
                    {{ number }}
                  </b-field>
                </div>
              </div>
            </template>
          </div>
          <div class="column is-2"></div>
        </div>
        <div class="columns">
          <div class="column is-2">
            <div class="level">
              <div class="level-left"></div>
              <div class="level-right" @copy="copyText()">
                {{ data.labelMin }}
              </div>
            </div>
          </div>
          <div class="column is-8">
            <template v-if="data.min == 0">
              <ValidationProvider
                :vid="`linearscaletype${indexSection}${indexAssessment}`"
                name="Linear Scale"
                :rules="`${data.require ? 'required' : ''}`"
                v-slot="{ errors }"
              >
                <div class="columns">
                  <div
                    v-for="(number, index) in data.max + 1"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="index"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
            <template v-else-if="data.min == 1">
              <ValidationProvider
                :vid="`linearscaletype${indexSection}${indexAssessment}`"
                name="Linear Scale"
                :rules="`${data.require ? 'required' : ''}`"
                v-slot="{ errors }"
              >
                <div class="columns">
                  <div
                    v-for="(number, index) in data.max"
                    :key="index"
                    class="column"
                  >
                    <b-field grouped position="is-centered">
                      <b-radio
                        class="scale-width"
                        type="is-hcc"
                        v-model="data.answer"
                        :native-value="number"
                      ></b-radio>
                    </b-field>
                  </div>
                </div>
                <span class="required">{{ errors[0] }}</span>
              </ValidationProvider>
            </template>
          </div>
          <div class="column is-2" @copy="copyText()">{{ data.labelMax }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexAssessment: {
      default: () => {},
    },
  },
  data() {
    return {
      linearScale: null,
    }
  },
  watch: {
    'data.answer': function() {
      this.submit()
    },
  },
  methods: {
    submit() {
      var today = new Date()

      var date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()

      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

      var dateTime = date + ' ' + time
      this.data.time = dateTime
      this.$emit('clicked')
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
  },
}
</script>
<style>
.scale-width {
  width: 10px !important;
}
</style>
