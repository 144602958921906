<template>
  <div>
    <template v-if="type == 'questionTest'">
      <template v-if="data.textRules.type == 'text'">
        <template v-if="data.textRules.typeLength == 'character'">
          <!-- For text -->
          <template v-if="data.textRules.min > 0">
            <span
              @copy="copyText()"
              style="font-size: 12px; padding-right: 10px"
            >
              Min Character : {{ data.textRules.min }}
            </span>
          </template>
          <template v-if="data.textRules.max > 0">
            <span @copy="copyText()" style="font-size: 12px">
              Max Character : {{ data.textRules.max }}
            </span>
          </template>

          <!-- For input field -->
          <template v-if="data.textRules.min == 0 && data.textRules.max == 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template
            v-else-if="data.textRules.min > 0 && data.textRules.max > 0"
          >
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, betweenchar: data.textRules }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.min > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, minchar: data.textRules.min }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.max > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, maxchar: data.textRules.max }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
        </template>
        <template v-else-if="data.textRules.typeLength == 'word'">
          <!-- For text -->
          <template v-if="data.textRules.min > 0">
            <span style="font-size: 12px; padding-right: 10px">
              Min Word : {{ data.textRules.min }}
            </span>
          </template>
          <template v-if="data.textRules.max > 0">
            <span style="font-size: 12px">
              Max Word : {{ data.textRules.max }}
            </span>
          </template>

          <!-- For input field -->
          <template v-if="data.textRules.min == 0 && data.textRules.max == 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template
            v-else-if="data.textRules.min > 0 && data.textRules.max > 0"
          >
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="text"
              :rules="{ required: data.require, betweenword: data.textRules }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.min > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, minword: data.textRules.min }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
          <template v-else-if="data.textRules.max > 0">
            <ValidationProvider
              :vid="`textType${indexSection}${indexAssessment}`"
              name="Text"
              :rules="{ required: data.require, maxword: data.textRules.max }"
              v-slot="{ errors }"
            >
              <b-field :type="errors.length > 0 ? 'is-danger' : null">
                <b-input
                  @copy.native="copyTextData($event)"
                  @paste.native="pasteText($event)"
                  type="textarea"
                  v-model="data.answer"
                ></b-input>
              </b-field>
              <span class="required">{{ errors[0] }}</span>
            </ValidationProvider>
          </template>
        </template>
      </template>
      <template v-else-if="data.textRules.type == 'email'">
        <span v-if="data.require">
          <ValidationProvider
            :vid="`emailType${indexSection}${indexAssessment}`"
            name="Email"
            :rules="`required|email`"
            v-slot="{ errors }"
          >
            <b-field :type="errors.length > 0 ? 'is-danger' : null">
              <b-input
                @copy.native="copyTextData($event)"
                @paste.native="pasteText($event)"
                type="email"
                v-model="data.answer"
              ></b-input>
            </b-field>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </span>
        <span v-else>
          <ValidationProvider
            :vid="`emailType${indexSection}${indexAssessment}`"
            name="Email"
            :rules="`email`"
            v-slot="{ errors }"
          >
            <b-field :type="errors.length > 0 ? 'is-danger' : null">
              <b-input
                @copy.native="copyTextData($event)"
                @paste.native="pasteText($event)"
                type="email"
                v-model="data.answer"
              ></b-input>
            </b-field>
            <span class="required">{{ errors[0] }}</span>
          </ValidationProvider>
        </span>
      </template>
      <template v-else-if="data.textRules.type == 'number'">
        <ValidationProvider
          :vid="`numberType${indexSection}${indexAssessment}`"
          name="Number"
          :rules="{ required: data.require }"
          v-slot="{ errors }"
        >
          <b-field :type="errors.length > 0 ? 'is-danger' : null">
            <b-input
              @copy.native="copyTextData($event)"
              @paste.native="pasteText($event)"
              type="number"
              v-model="data.answer"
            ></b-input>
          </b-field>
          <span class="required">{{ errors[0] }}</span>
        </ValidationProvider>
      </template>
    </template>
    <template v-else-if="type == 'introductionTest'">
      <div @copy="copyText()">
        {{ data.answer }}
      </div>
    </template>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'

export default {
  props: {
    data: {
      default: () => {},
    },
    type: {
      default: () => {},
    },
    rulesData: {
      default: () => {},
    },
    indexSection: {
      default: () => {},
    },
    indexAssessment: {
      default: () => {},
    },
  },
  watch: {
    'data.answer': debounce(function() {
      this.submit()
    }, 500),
  },
  methods: {
    submit() {
      var today = new Date()

      var date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate()

      var time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()

      var dateTime = date + ' ' + time
      this.data.time = dateTime
      this.$emit('clicked')
    },
    copyText() {
      const selection = document.getSelection()

      this.$emit('copy-text', selection.toString())
    },
    pasteText(event) {
      this.$emit('paste-text', event.target.value)
    },
    copyTextData(event) {
      this.$emit('copy-text', event.target.value)
    },
  },
}
</script>
