<template>
  <div @copy="copyText($event)">
    {{ data.question }}
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: () => {},
    },
  },
  methods: {
    copyText() {
      const selection = document.getSelection();

      this.$emit("copy-text", selection.toString());
    },
  },
};
</script>
