<template>
  <div v-if="!isLoading">
    <div class="container" @contextmenu="handlerRightClick($event)">
      <div class="columns is-multiline is-centered">
        <div class="is-hidden-desktop header-mobile column is-12">
          <span class="columns is-mobile header-mobile-column">
            <span class="column is-3 time-mobile">
              <b>{{ countdown }}</b>
            </span>
            <span class="column is-5 progress-mobile">
              <b-progress
                type="is-hcc"
                :value="progress"
                show-value
                format="percent"
              ></b-progress>
            </span>
            <span class="column is-4 btn-next-mobile">
              <b-field grouped position="is-right">
                <span id="countdown" class="timer"></span>
                <b-button
                  type="is-hcc"
                  @click="validationNextSection()"
                  v-if="progress != 100"
                >
                  Next
                </b-button>
              </b-field>
            </span>
          </span>
        </div>
        <div class="is-hidden-mobile header-desktop column is-12">
          <span class="columns header-desktop-column">
            <span class="column is-1">
              <b class="is-size-5">{{ countdown }}</b>
            </span>
            <span class="column is-6 bar">
              <b-field grouped position="is-right">
                <b-progress
                  type="is-hcc"
                  :value="progress"
                  show-value
                  format="percent"
                ></b-progress>
              </b-field>
            </span>
            <span class="column is-5 btn-next">
              <b-field grouped position="is-right">
                <span id="countdown" class="timer"></span>
                <b-button
                  type="is-hcc"
                  @click="validationNextSection()"
                  v-if="progress != 100"
                >
                  Next
                </b-button>
              </b-field>
            </span>
          </span>
        </div>
      </div>
      <span class="columns is-multiline">
        <div class="contents column is-12">
          <b-steps :has-navigation="false" v-model="activeStep" :size="size">
            <b-step-item type="is-hcc">
              <div class="column is-12">
                <label class="label">
                  <b>
                    <b-field grouped position="is-centered">
                      <h1 class="is-size-3">
                        Silakan mengambil foto sebelum melanjutkan ke sesi
                        berikutnya
                      </h1>
                    </b-field>
                  </b>
                </label>
              </div>
              <div class="column is-12">
                <b-field grouped position="is-centered">
                  <div class="photo" v-if="file">
                    <img id="test" v-lazy="file" />
                    <b-field grouped position="is-centered">
                      <b-button type="is-hcc" @click="modalTakePhoto()">
                        Take a photo
                      </b-button>
                    </b-field>
                  </div>
                  <div class="photo" v-else>
                    <img src="../../assets/image/user.png" />
                    <b-field
                      grouped
                      position="is-centered"
                      class="btn-take-photo"
                    >
                      <b-button
                        type="is-hcc"
                        size="is-medium"
                        @click="modalTakePhoto()"
                      >
                        Ambil Foto
                      </b-button>
                    </b-field>
                  </div>
                </b-field>
              </div>
              <b-modal :active.sync="isTakePhotoModal" :width="400">
                <div id="app" class="card web-camera-container">
                  <div class="camera-box" :class="{ flash: isShotPhoto }">
                    <div
                      class="camera-shutter"
                      :class="{ flash: isShotPhoto }"
                    ></div>

                    <video
                      v-show="!isPhotoTaken"
                      ref="camera"
                      :width="450"
                      :height="337.5"
                      autoplay
                    ></video>

                    <canvas
                      v-show="isPhotoTaken"
                      id="photoTaken"
                      ref="canvas"
                      :width="450"
                      :height="337.5"
                    ></canvas>
                  </div>

                  <div
                    v-if="isCameraOpen && !isLoadingCamera"
                    class="camera-shoot"
                  >
                    <button type="button" class="button" @click="takePhoto">
                      <img
                        src="https://img.icons8.com/material-outlined/50/000000/camera--v2.png"
                      />
                    </button>
                  </div>
                </div>
              </b-modal>
            </b-step-item>
            <b-step-item>
              <div class="column is-12">
                <div
                  v-bind:style="{
                    backgroundImage: 'url(' + imageUrl + ')',
                  }"
                  class="image-background"
                >
                  <h3 class="is-size-3">
                    <b>{{ introduction.title }}</b>
                  </h3>
                </div>
                <div v-html="introduction.rules"></div>
                <video
                  hidden
                  v-show="!isPhotoTaken"
                  ref="cameraHidden"
                  :width="450"
                  :height="337.5"
                  autoplay
                ></video>
                <canvas
                  hidden
                  id="hiddenCanvas"
                  ref="canvasHidden"
                  :width="450"
                  :height="337.5"
                ></canvas>
              </div>
            </b-step-item>

            <!-- ini untuk looping section-->
            <b-step-item
              v-for="(section, indexSection) in listSection"
              :key="indexSection"
            >
              <!-- ini untuk section introduction-->
              <div
                v-if="section.type == 'introduction'"
                class="columns is-multiline box"
              >
                <div class="column is-12">
                  <div>
                    {{ section.title }}
                    <div v-html="section.description"></div>
                  </div>
                </div>
              </div>
              <!-- ini untuk looping question di setiap section -->
              <div class="columns is-multiline box" v-else>
                <div class="column is-12">
                  <div
                    class="box question"
                    v-if="section.introductionPreviewTest"
                  >
                    <div v-html="section.introductionPreviewTest"></div>
                  </div>
                  <ValidationObserver :ref="'formRegister-' + indexSection">
                    <form @submit.prevent="nextSection">
                      <!-- For title question -->
                      <span
                        v-for="(group, indexGroup) in section.introductionGroup"
                        :key="indexGroup"
                        class="section"
                      >
                        <template v-if="group.isUseQuestion">
                          <div
                            class="box question"
                            :style="
                              `background-image: url(${require('../../assets/image/watermark-dummy.png')})`
                            "
                            style="margin-top: 20px"
                            v-show="
                              section.rules.questionToDisplay *
                                (currentPage - 1) <
                                group.index &&
                                section.rules.questionToDisplay * currentPage >=
                                  group.index
                            "
                          >
                            <!-- For type question -->
                            <div class="columns is-12">
                              <div class="column is-12">
                                <!-- For Text Type -->
                                <template v-if="group.questionType == 'text'">
                                  <QuestionTypeText :data="group" />
                                </template>

                                <!-- For Image Type -->
                                <template
                                  v-else-if="group.questionType == 'image'"
                                >
                                  <QuestionTypeImage :data="group" />
                                </template>

                                <!-- For Video Type -->
                                <template
                                  v-else-if="group.questionType == 'video'"
                                >
                                  <QuestionTypeVideo :data="group" />
                                </template>

                                <!-- For Free Text Type -->
                                <template
                                  v-if="group.questionType == 'free_text'"
                                >
                                  <QuestionTypeFreeText :data="group" />
                                </template>

                                <!-- For Youtube Link Type -->
                                <template
                                  v-else-if="group.questionType == 'youtube'"
                                >
                                  <QuestionTypeYoutubeLink :data="group" />
                                </template>

                                <!-- For Image And Text Type -->
                                <template
                                  v-else-if="group.questionType == 'text_image'"
                                >
                                  <QuestionTypeTextImage :data="group" />
                                </template>

                                <!-- For Video And Text Type -->
                                <template
                                  v-else-if="group.questionType == 'text_video'"
                                >
                                  <QuestionTypeTextVideo :data="group" />
                                </template>

                                <!-- For Youtube Link And Text Type -->
                                <template
                                  v-else-if="
                                    group.questionType == 'text_youtube'
                                  "
                                >
                                  <QuestionTypeTextYoutubeLink :data="group" />
                                </template>

                                <!-- For Image And Free Text Type -->
                                <template
                                  v-else-if="
                                    group.questionType == 'free_text_image'
                                  "
                                >
                                  <QuestionTypeFreeTextImage :data="group" />
                                </template>

                                <!-- For Video And Free Text Type -->
                                <template
                                  v-else-if="
                                    group.questionType == 'free_text_video'
                                  "
                                >
                                  <QuestionTypeFreeTextVideo :data="group" />
                                </template>

                                <!-- For Youtube Link And Free Text Type -->
                                <template
                                  v-else-if="
                                    group.questionType == 'free_text_youtube'
                                  "
                                >
                                  <QuestionTypeFreeTextYoutubeLink
                                    :data="group"
                                  />
                                </template>

                                <!-- For All Type -->
                                <template
                                  v-else-if="group.questionType == 'all'"
                                >
                                  <QuestionTypeAll :data="group" />
                                </template>
                              </div>
                            </div>
                          </div>
                        </template>
                        <span
                          v-for="(assessment,
                          indexAssessment) in group.assessment"
                          :key="indexAssessment"
                        >
                          <div
                            class="box question"
                            :style="
                              `background-image: url(${require('../../assets/image/watermark-dummy.png')})`
                            "
                            v-show="
                              section.rules.questionToDisplay *
                                (currentPage - 1) <
                                assessment.index &&
                                section.rules.questionToDisplay * currentPage >=
                                  assessment.index
                            "
                          >
                            <div class="columns is-12">
                              <div
                                :id="
                                  'group' +
                                    group.groupId +
                                    '-' +
                                    assessment.questionId
                                "
                              ></div>
                              <div class="column is-2">
                                <span class="handle">
                                  <i
                                    class="glyphicon glyphicon-menu-hamburger"
                                  ></i>
                                </span>
                                <b>
                                  #{{
                                    generateQuestionNumber(
                                      indexAssessment,
                                      indexGroup,
                                      section.introductionGroup
                                    )
                                  }}
                                  <span
                                    v-if="assessment.require == true"
                                    class="required"
                                  >
                                    *
                                  </span>
                                </b>
                              </div>

                              <!-- For type question -->
                              <div class="column is-10">
                                <div class="columns is-12">
                                  <div class="column is-12">
                                    <!-- For Text Type -->
                                    <template
                                      v-if="assessment.questionType == 'text'"
                                    >
                                      <QuestionTypeText :data="assessment" />
                                    </template>

                                    <!-- For Image Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'image'
                                      "
                                    >
                                      <QuestionTypeImage
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Video Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'video'
                                      "
                                    >
                                      <QuestionTypeVideo
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Youtube Link Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'youtube'
                                      "
                                    >
                                      <QuestionTypeYoutubeLink
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Free Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'free_text'
                                      "
                                    >
                                      <QuestionTypeFreeText
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Image And Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'text_image'
                                      "
                                    >
                                      <QuestionTypeTextImage
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Video And Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'text_video'
                                      "
                                    >
                                      <QuestionTypeTextVideo
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Youtube Link And Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType ==
                                          'text_youtube'
                                      "
                                    >
                                      <QuestionTypeTextYoutubeLink
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Free Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType ==
                                          'free_text_image'
                                      "
                                    >
                                      <QuestionTypeFreeTextImage
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Free Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType ==
                                          'free_text_video'
                                      "
                                    >
                                      <QuestionTypeFreeTextVideo
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For Free Text Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType ==
                                          'free_text_youtube'
                                      "
                                    >
                                      <QuestionTypeFreeTextYoutubeLink
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>

                                    <!-- For All Type -->
                                    <template
                                      v-else-if="
                                        assessment.questionType == 'all'
                                      "
                                    >
                                      <QuestionTypeAll
                                        :data="assessment"
                                        :type="type"
                                      />
                                    </template>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <!-- For answer quetion -->
                            <div
                              class="columns is-12"
                              v-if="assessment.type != 'question'"
                            >
                              <div class="column is-2">
                                <b>Answer</b>
                              </div>
                              <div class="column is-10">
                                <!-- *Note : textarea dan textfield componentnya ditukar sementara -->
                                <!-- For Type Textfield -->
                                <template v-if="assessment.type == 'textfield'">
                                  <AnswerTypeTextarea
                                    :data="assessment"
                                    :type="section.type"
                                    :rulesData="section.rules"
                                    :indexSection="indexGroup"
                                    :indexAssessment="indexAssessment"
                                  />
                                </template>

                                <!-- For Type Textarea -->
                                <!-- <template v-else-if="assessment.type == 'textarea'">
                                                <AnswerTypeTextfield :data="assessment" :title="section.type" />
                                        </template>-->

                                <!-- For Type Multiple Choice -->
                                <template
                                  v-else-if="assessment.type == 'radio-button'"
                                >
                                  <AnswerTypeRadioButton
                                    :data="assessment"
                                    :indexSection="indexGroup"
                                    :indexAssessment="indexAssessment"
                                    :rulesData="section.rules"
                                    :type="section.type"
                                  />
                                </template>

                                <!-- For Type Checkbox -->
                                <template
                                  v-else-if="assessment.type == 'checkbox'"
                                >
                                  <AnswerTypeCheckbox
                                    :data="assessment"
                                    :indexSection="indexGroup"
                                    :indexAssessment="indexAssessment"
                                    :rulesData="section.rules"
                                    :type="section.type"
                                  />
                                </template>

                                <!-- For Type Linear Scale -->
                                <template
                                  v-else-if="assessment.type == 'linear-scale'"
                                >
                                  <AnswerTypeLinearScale
                                    :data="assessment"
                                    :indexSection="indexGroup"
                                    :indexAssessment="indexAssessment"
                                  />
                                </template>

                                <!-- For Type Ranking -->
                                <template
                                  v-else-if="assessment.type == 'ranking'"
                                >
                                  <AnswerTypeRanking
                                    :data="assessment"
                                    :rulesData="section.rules"
                                    :indexSection="indexGroup"
                                    :indexAssessment="indexAssessment"
                                  />
                                </template>
                              </div>
                            </div>
                          </div>
                        </span>
                      </span>
                    </form>
                  </ValidationObserver>
                </div>
                <span class="column is-12">
                  <b-pagination
                    :current.sync="currentPage"
                    :total="section.counter"
                    :per-page="section.rules.questionToDisplay"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                  ></b-pagination>
                </span>
              </div>
            </b-step-item>

            <!-- ini untuk thank you page -->
            <b-step-item class="step-thank-you">
              <h3>{{ thankYouPage.title }}</h3>
              <div v-html="thankYouPage.description"></div>
            </b-step-item>
          </b-steps>
        </div>
      </span>
    </div>
  </div>
</template>
<script>
import QuestionTypeText from '@/components/QuestionType/QuestionTypeText'
import QuestionTypeImage from '@/components/QuestionType/QuestionTypeImage'
import QuestionTypeVideo from '@/components/QuestionType/QuestionTypeVideo'
import QuestionTypeYoutubeLink from '@/components/QuestionType/QuestionTypeYoutubeLink'
import QuestionTypeFreeText from '@/components/QuestionType/QuestionTypeFreeText'
import QuestionTypeTextImage from '@/components/QuestionType/QuestionTypeTextImage'
import QuestionTypeTextVideo from '@/components/QuestionType/QuestionTypeTextVideo'
import QuestionTypeTextYoutubeLink from '@/components/QuestionType/QuestionTypeTextYoutubeLink'
import QuestionTypeFreeTextImage from '@/components/QuestionType/QuestionTypeFreeTextImage'
import QuestionTypeFreeTextVideo from '@/components/QuestionType/QuestionTypeFreeTextVideo'
import QuestionTypeFreeTextYoutubeLink from '@/components/QuestionType/QuestionTypeFreeTextYoutubeLink'
import QuestionTypeAll from '@/components/QuestionType/QuestionTypeAll'

// For Answer Vue Component
// import AnswerTypeTextfield from "@/components/AnswerType/AnswerTypeTextfield";
import AnswerTypeTextarea from '@/components/AnswerType/AnswerTypeTextarea'
import AnswerTypeRadioButton from '@/components/AnswerType/AnswerTypeRadioButton'
import AnswerTypeCheckbox from '@/components/AnswerType/AnswerTypeCheckbox'
import AnswerTypeLinearScale from '@/components/AnswerType/AnswerTypeLinearScale'
import AnswerTypeRanking from '@/components/AnswerType/AnswerTypeRanking'

export default {
  components: {
    // Countdown,
    // Using Question Component
    QuestionTypeText,
    QuestionTypeImage,
    QuestionTypeVideo,
    QuestionTypeYoutubeLink,
    QuestionTypeFreeText,
    QuestionTypeTextImage,
    QuestionTypeTextVideo,
    QuestionTypeTextYoutubeLink,
    QuestionTypeFreeTextImage,
    QuestionTypeFreeTextVideo,
    QuestionTypeFreeTextYoutubeLink,
    QuestionTypeAll,

    // Using Answer COmponent
    // AnswerTypeTextfield,
    AnswerTypeTextarea,
    AnswerTypeRadioButton,
    AnswerTypeCheckbox,
    AnswerTypeLinearScale,
    AnswerTypeRanking,
  },
  data() {
    return {
      position: 'left',
      progress: 0,
      activeStep: 0,
      size: 'small',
      isCameraOpen: false,
      isPhotoTaken: false,
      isShotPhoto: false,
      isLoading: true,
      isLoadingCamera: false,
      introduction: [],
      information: [],
      settings: [],
      rules: [],
      listSection: [],
      thankYouPage: [],
      isTakePhotoModal: false,
      link: '#',
      isProcessPhoto: false,
      file: null,
      file2: null,
      isNotFound: false,
      count: 0,
      imageUrl: '',
      title: 'Introduction Test',
      type: "'form'",
      isAuthorized: true,
      isCountDown: false,
      seconds: 0,
      countdown: '',
      section: 0,
      timerInterval: 0,
      updateSeconds: 0,
      watermark: null,
      currentPage: 1,
      isUpdatedContent: false,
      ssInterval: null,
      imgs: [],
      isCameraOff: false,
      questionNumberIdList: [],
    }
  },
  created() {
    this.getPreview()
  },
  watch: {
    activeStep: function(val) {
      if (val > 2) {
        this.section += 1
        if (this.isCountDown == false) {
          clearInterval(this.timerInterval)
        }
        if (val == 2 + this.listSection.length) {
          this.isCountDown = false
          this.seconds = 0
          this.countdown = ''
          this.progress = 100
        } else {
          if (this.listSection[this.section].type == 'questionTest') {
            if (this.listSection[this.section].rules.isUseTime) {
              if (this.isUpdatedContent) {
                this.seconds = this.updateSeconds
              } else {
                this.seconds = this.listSection[this.section].rules.time
              }
              this.isCountDown = true
              this.timerInterval = setInterval(() => this.timer(), 1000)
            } else {
              this.isCountDown = false
              this.seconds = 0
              this.countdown = ''
            }
          } else {
            this.isCountDown = false
            this.seconds = 0
            this.countdown = ''
          }
        }
      }
      this.currentPage = 1
      this.questionNumberIdList = []
      this.progress = (val / (2 + this.listSection.length)) * 100
    },
  },
  methods: {
    getBackground(watermark) {
      return watermark
    },
    handlerRightClick(event) {
      event.preventDefault()
    },
    generateQuestionNumber(indexAssessment, indexGroup, section) {
      var result = 0
      if (indexGroup == 0) {
        result = indexAssessment + 1
      } else {
        var temporaryNumberGenerate = 0
        var i
        for (i = 0; i < indexGroup; i++) {
          temporaryNumberGenerate += section[i].assessment.length
        }
        result = temporaryNumberGenerate + indexAssessment + 1
      }
      return result
    },
    popUpMoveToNextSection() {
      this.$buefy.dialog.confirm({
        title: 'Move To other section',
        message: `<strong style="color: orange;">PERHATIAN:</strong> Anda akan <strong>MENGAKHIRI BAGIAN INI</strong> dan <strong>BERPINDAH</strong> ke <strong>BAGIAN BERIKUTNYA.</strong> Apakah Anda sudah <strong>YAKIN</strong> dengan <strong>SELURUH JAWABAN</strong> Anda?`,
        cancelText: 'Belum, saya ingin mengeceknya kembali.',
        confirmText: 'Sudah, saya ingin berpindah ke halaman berikutnya',
        type: 'is-success',
        onConfirm: () => this.nextSection(),
      })
    },
    async getPreview() {
      this.isLoading = true
      const psikotestIntroductionResponse = await this.$store
        .dispatch('getPsikotestIntroduction', this.$route.params.id)
        .catch(error => {
          if (error.response.status == 401) {
            this.isAuthorized = false
            return error
          }
        })
      const response = await this.$store.dispatch(
        'previewAssessments',
        this.$route.params.id
      )

      this.introduction = psikotestIntroductionResponse.data
      this.information = response.data.information
      this.settings = response.data.settings
      this.thankYouPage = response.data.thankYouPage
      this.listSection = response.data.section
      var path = JSON.parse(this.introduction.bannerImages).file_url
      this.imageUrl = "'" + process.env.VUE_APP_BACKEND_URL + path + "'"
      this.isLoading = false
    },
    timer() {
      var hoursLeft = Math.floor(this.seconds - 0 * 86400)
      var hours = Math.floor(hoursLeft / 3600)
      var minutesLeft = Math.floor(hoursLeft - hours * 3600)
      var minutes = Math.floor(minutesLeft / 60)
      var remainingSeconds = this.seconds % 60
      this.countdown =
        this.pad(hours) +
        ':' +
        this.pad(minutes) +
        ':' +
        this.pad(remainingSeconds)
      if (this.seconds != 0) {
        this.seconds = this.seconds - 1
      } else {
        this.isCountDown = false
        this.seconds = 0
        this.countdown = ''
        this.activeStep += 1
      }
    },
    pad(n) {
      return n < 10 ? '0' + n : n
    },
    jump(group, assessment) {
      var jumpTo = 'group' + group + '-' + assessment
      document.getElementById(jumpTo).scrollIntoView()
    },
    validationNextSection() {
      if (this.listSection[this.section].type == 'questionTest') {
        this.popUpMoveToNextSection()
      } else {
        this.nextSection()
      }
    },
    nextSection() {
      document.documentElement.scrollTop = 0
      if (
        this.listSection[this.section].type == 'questionTest' ||
        this.listSection[this.section].type == 'introductionTest'
      ) {
        this.$refs['formRegister-' + this.section][0]
          .validate()
          .then(success => {
            if (!success) {
              this.danger(
                'Ada soal yang Anda lewati. Kerjakan dulu soal tersebut untuk dapat melanjutkan proses ke halaman berikutnya.'
              )
            } else {
              if (this.activeStep == 0) {
                if (this.file != null) {
                  this.activeStep += 1
                } else {
                  this.danger(
                    'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya'
                  )
                }
              } else {
                if (this.activeStep != this.listSection.length + 2) {
                  this.activeStep += 1
                  // this.section += 1;
                  this.seconds = 0
                  this.isCountDown = false
                }
              }
            }
          })
      } else {
        if (this.activeStep == 0) {
          if (this.file != null) {
            this.activeStep += 1
          } else {
            this.danger(
              'Silahkan mengambil foto sebelum melanjutkan ke sesi berikutnya'
            )
          }
        } else {
          if (this.activeStep != this.listSection.length + 2) {
            this.activeStep += 1
          }
        }
      }
    },
    modalTakePhoto() {
      this.isCameraOpen = true
      this.createCameraElement()
      this.isTakePhotoModal = true
    },
    toggleCamera() {
      if (this.isCameraOpen) {
        this.isCameraOpen = false
        this.isPhotoTaken = false
        this.isShotPhoto = false
        this.stopCameraStream()
      } else {
        this.isCameraOpen = true
        this.createCameraElement()
      }
    },
    createCameraElement() {
      this.isLoadingCamera = true

      const constraints = (window.constraints = {
        audio: false,
        video: true,
      })

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(stream => {
          this.isLoadingCamera = false
          this.$refs.camera.srcObject = stream
        })
        .catch(() => {
          this.isLoadingCamera = false
          this.isCameraOff = true
          alert('Kamera tidak ditemukan')
        })
    },
    async takePhoto() {
      this.file = null
      this.isProcessPhoto = false
      if (!this.isPhotoTaken) {
        this.isShotPhoto = true

        const FLASH_TIMEOUT = 50

        setTimeout(() => {
          this.isShotPhoto = false
        }, FLASH_TIMEOUT)
      }

      const context = this.$refs.canvas.getContext('2d')
      context.drawImage(this.$refs.camera, 0, 0, 450, 337.5)
      this.file = document.getElementById('photoTaken').toDataURL('image/jpeg')
      this.isProcessPhoto = true
      this.isTakePhotoModal = false
      this.stopCameraStream()
    },
    stopCameraStream() {
      let tracks = this.$refs.camera.srcObject.getTracks()

      tracks.forEach(track => {
        track.stop()
      })
    },
    success(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-success',
      })
    },
    danger(message) {
      this.$buefy.toast.open({
        message: message,
        type: 'is-danger',
      })
    },
  },

  createCameraElement() {
    this.isLoadingCamera = true

    const constraints = (window.constraints = {
      audio: false,
      video: true,
    })

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(stream => {
        this.isLoadingCamera = false
        this.$refs.camera.srcObject = stream
      })
      .catch(() => {
        this.isLoadingCamera = false
        this.isCameraOff = true
        alert('Kamera tidak ditemukan')
      })
  },
}
</script>
<style lang="scss">
.b-steps .steps {
  display: none !important;
}
.jump-button {
  width: 3%;
  margin-top: 1%;
  margin-right: 1%;
}
.header {
  &-mobile {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 1%;
    padding-top: 1%;
    height: 10%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }

  &-desktop {
    overflow: hidden;
    background-color: whitesmoke;
    position: fixed;
    top: 0;
    z-index: 1;
    width: 100%;
    margin-left: 0;
    padding-top: 1%;

    &-column {
      margin-left: 0.25rem !important;
    }
  }
}
.btn-next-mobile {
  padding-top: 6.5%;
  padding-right: 3%;
}
.progress-mobile {
  margin-top: 6%;
}
.time-mobile {
  margin-top: 5%;
}
.pagination-footer {
  overflow: hidden;
  position: fixed;
  background-color: whitesmoke;
  bottom: 0;
  z-index: 1;
  width: 100%;
  margin-left: 1%;
  padding-bottom: 1%;
}
.footer-column {
  margin-left: 0.25rem !important;
}

@media (min-width: 360px) and (max-width: 1000px) {
  .toast {
    width: 95%;
  }
  .modal-card-foot > button {
    height: auto !important;
    font-size: 1.25rem !important;
    white-space: normal !important;
  }
}
@media (min-width: 360px) {
  .contents {
    padding-top: 11%;
  }
  .step-thank-you {
    padding-top: 7%;
  }
}
@media screen and (min-width: 1024px) {
  .step-thank-you {
    padding-top: 0;
  }
  .contents {
    padding-top: 4%;
  }
  .bar {
    padding-right: 2.5%;
    padding-top: 1.3%;
  }
  .btn-next {
    padding-top: 0.5%;
    padding-right: 3%;
  }
}
@media screen and (min-width: 1408px) {
  .bar {
    padding-right: 2.5%;
    padding-top: 0.7%;
  }
  .btn-next {
    padding-top: 0.5%;
    padding-right: 2.2%;
  }
}
.navbar.is-spaced {
  padding-right: 2.5%;
  padding-top: 0% !important;
}
.image-background {
  width: 100%;
  height: auto;
  text-align: center;
  color: white;
}
html,
body,
.height {
  width: 100% !important;
}
.btn-take-photo {
  padding-top: 5%;
}
.steps {
  display: none !important;
}

ul.pagination-list {
  list-style-type: none;
}

span.column.is-12 {
  position: fixed;
  background-color: whitesmoke;
  right: 0rem;
  left: 0rem;
  width: 100%;
  padding: 0.5rem;
  bottom: 0;
  display: flex;
  justify-content: center;
}

body {
  display: flex;
  justify-content: center;
}
.photo {
  width: 360px;
  padding: 1%;
  border: 1px solid black;
}
.section {
  padding-top: 0.5rem !important;
}
@media screen and (min-width: 1216px) {
  .progress-wrapper {
    /* padding-top: 1%; */
    width: 23%;
  }
}
@media screen and (min-width: 1408px) {
  .progress-wrapper {
    /* padding-top: 1%; */
    width: 25%;
  }
  .progress {
    height: 23px !important;
  }
  .progress-wrapper .progress-value {
    top: 17% !important;
    font-size: 15px !important;
  }
}
.web-camera-container {
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;

  .camera-button {
    margin-bottom: 2rem;
  }

  .camera-box {
    .camera-shutter {
      opacity: 0;
      width: 450px;
      height: 337.5px;
      background-color: #fff;
      position: absolute;

      &.flash {
        opacity: 1;
      }
    }
  }

  .camera-shoot {
    margin: 1rem 0;

    button {
      height: 60px;
      width: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;

      img {
        height: 35px;
        object-fit: cover;
      }
    }
  }

  .camera-loading {
    overflow: hidden;
    height: 100%;
    position: absolute;
    width: 100%;
    min-height: 150px;
    margin: 3rem 0 0 -1.2rem;

    ul {
      height: 100%;
      position: absolute;
      width: 100%;
      z-index: 999999;
      margin: 0;
    }

    .loader-circle {
      display: block;
      height: 14px;
      margin: 0 auto;
      top: 50%;
      left: 100%;
      transform: translateY(-50%);
      transform: translateX(-50%);
      position: absolute;
      width: 100%;
      padding: 0;

      li {
        display: block;
        float: left;
        width: 10px;
        height: 10px;
        line-height: 10px;
        padding: 0;
        position: relative;
        margin: 0 0 0 4px;
        background: #999;
        animation: preload 1s infinite;
        top: -50%;
        border-radius: 100%;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }
      }
    }
  }

  @keyframes preload {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 1;
    }
  }
}
ul {
  list-style: disc inside;
}
ol {
  list-style: inside decimal;
}
</style>
