var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type == 'questionTest')?[(_vm.data.textRules.type == 'text')?[(_vm.data.textRules.typeLength == 'character')?[(_vm.data.textRules.min > 0)?[_c('span',{staticStyle:{"font-size":"12px","padding-right":"10px"},on:{"copy":function($event){return _vm.copyText()}}},[_vm._v(" Min Character : "+_vm._s(_vm.data.textRules.min)+" ")])]:_vm._e(),(_vm.data.textRules.max > 0)?[_c('span',{staticStyle:{"font-size":"12px"},on:{"copy":function($event){return _vm.copyText()}}},[_vm._v(" Max Character : "+_vm._s(_vm.data.textRules.max)+" ")])]:_vm._e(),(_vm.data.textRules.min == 0 && _vm.data.textRules.max == 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:(_vm.data.textRules.min > 0 && _vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, betweenchar: _vm.data.textRules }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:(_vm.data.textRules.min > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, minchar: _vm.data.textRules.min }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:(_vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, maxchar: _vm.data.textRules.max }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:_vm._e()]:(_vm.data.textRules.typeLength == 'word')?[(_vm.data.textRules.min > 0)?[_c('span',{staticStyle:{"font-size":"12px","padding-right":"10px"}},[_vm._v(" Min Word : "+_vm._s(_vm.data.textRules.min)+" ")])]:_vm._e(),(_vm.data.textRules.max > 0)?[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v(" Max Word : "+_vm._s(_vm.data.textRules.max)+" ")])]:_vm._e(),(_vm.data.textRules.min == 0 && _vm.data.textRules.max == 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:(_vm.data.textRules.min > 0 && _vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"text","rules":{ required: _vm.data.require, betweenword: _vm.data.textRules }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:(_vm.data.textRules.min > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, minword: _vm.data.textRules.min }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:(_vm.data.textRules.max > 0)?[_c('ValidationProvider',{attrs:{"vid":("textType" + _vm.indexSection + _vm.indexAssessment),"name":"Text","rules":{ required: _vm.data.require, maxword: _vm.data.textRules.max }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"textarea"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2843286533)})]:_vm._e()]:_vm._e()]:(_vm.data.textRules.type == 'email')?[(_vm.data.require)?_c('span',[_c('ValidationProvider',{attrs:{"vid":("emailType" + _vm.indexSection + _vm.indexAssessment),"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"email"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1855185699)})],1):_c('span',[_c('ValidationProvider',{attrs:{"vid":("emailType" + _vm.indexSection + _vm.indexAssessment),"name":"Email","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"email"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1855185699)})],1)]:(_vm.data.textRules.type == 'number')?[_c('ValidationProvider',{attrs:{"vid":("numberType" + _vm.indexSection + _vm.indexAssessment),"name":"Number","rules":{ required: _vm.data.require }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"type":errors.length > 0 ? 'is-danger' : null}},[_c('b-input',{attrs:{"type":"number"},nativeOn:{"copy":function($event){return _vm.copyTextData($event)},"paste":function($event){return _vm.pasteText($event)}},model:{value:(_vm.data.answer),callback:function ($$v) {_vm.$set(_vm.data, "answer", $$v)},expression:"data.answer"}})],1),_c('span',{staticClass:"required"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2239370348)})]:_vm._e()]:(_vm.type == 'introductionTest')?[_c('div',{on:{"copy":function($event){return _vm.copyText()}}},[_vm._v(" "+_vm._s(_vm.data.answer)+" ")])]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }